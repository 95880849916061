import React from 'react';
import './contact-us.scss';
import Whatsapp from '../../assets/icons/greenWhatsapp.svg';
import Telegram from '../../assets/icons/blueTelegram.svg';
import Mobile from '../../assets/icons/blueMobile.svg';
import MainContainer from '../containers/mainContainer';
import Title from '../utils/title';
import FollowUsInSocials from '../utils/followUpInSocials';
import GoogleMaps from "../../assets/icons/googleMapsIcon.svg";

function ContactUs() {
    return (
        <MainContainer>
            <Title
            goldText={''}
            blackText={'تماس با ما'}/>
            <div className={'contactUsContainer'}>
                <p>
                    طومار به عنوان بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی/ترحیم در خدمت شما عزیزان میباشد.
                </p>
                <p>
                    توجه توجه <span>لطفا قبل از تماس گرفتن متن زیر و قسمت <a href="/repetitive-questions" target='_blank' style={{color: "#C69C61", textDecoration: "underline"}}>سوالات متداول</a> را مطالعه کنید</span>
                </p>
                <p>
                مدت زمان آماده سازی و ارسال محصولات برای سفارشات داخل تهران 3 الی 5 روز کاری از لحظه ثبت سفارش و برای خارج از تهران 4 الی 7 روز کاری میباشد.                </p>
                <p>
                در سفارشات دعوتنامه ها و محصولات چاپی و دیجیتال قبل از چاپ و نهایی کردن طرح ، عکس متن طراحی شده(در تلگرام، واتساپ یا سایر پیام رسان ها)برایتان ارسال میشود که تایید کنید.
                </p>
                <p style={{color: "red", textAlign: "center", fontSize: "12px"}}>
                تمامی قیمت های ذکر شده ، قیمت نهایی محصولات (کارت های دعوت شامل هزینه چاپ و طراحی و... ) میباشد و قیمت ها بروز است.
                </p>
                <p>
                    مشاوره و راهنمایی <span>زمان پاسخگویی تلفنی شنبه تا چهارشنبه از ۹ صبح تا ۶ بعد از ظهر (پنجشنبه ها از ۹ صبح تا ۳ بعد از ظهر)</span>
                </p>
                <div>
                    <p>
                        برای مشاوره و راهنمایی در مورد ثبت سفارش با شماره های زیر تماس بگیرید:<span style={{color: "red", fontSize: "12px"}}>(پیشنهاد میشود قبل از تماس به قسمت <a href="/repetitive-questions" target='_blank' style={{color: "black", textDecoration: "underline"}}>سوالات متداول</a> مراجعه نمایید)</span>

                    </p>
                    <div>
                        <div>
                            <img src={Mobile} alt={'mobile'}/>
                            <p>
                                تماس تلفنی
                            </p>
                            <div>
                            <p>
                                ۰۲۱۴۴۱۳۷۱۷۹ - ۰۹۰۵۴۸۱۶۴۴۱
                            </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <a href={'http://wa.me/989054816441'} target={'_blank'} style={{display: "flex", flexDirection: "column", alignItems: 'center'}}>
                            <img src={Whatsapp} alt={'whatsapp'}/>
                            <p style={{color: "black", fontSize: "12px", margin: "4px 0px 0px"}}>
                                واتساپ راهنمایی سفارشات
                            </p>
                        </a>
                        <a href={'http://t.me/toomar_aroosicards'} target={'_blank'} style={{display: "flex", flexDirection: "column", alignItems: 'center'}}>
                            <img src={Telegram} alt={'telegram'}/>
                            <p style={{color: "black", fontSize: "12px", margin: "4px 0px 0px"}}>
                                تلگرام راهنمایی سفارشات
                            </p>
                        </a>
                    </div>
                </div>
                <p>
                    تماس برای پیگیری سفارشات <span>زمان پاسخگویی تلفنی شنبه تا چهارشنبه از ۹ صبح تا ۶ بعد از ظهر (پنجشنبه ها از ۹ صبح تا ۳ بعد از ظهر)</span>
                </p>
                <p>
                    پس از ثبت سفارش شما عزیزان؛ لینکی حاوی پیگیری سفارش برایتان پیامک میشود که میتوانید با کلیک بر روی آن لینک از وضعیت سفارش خود مطلع شوید.
                </p>
                <div>
                    <div>
                        <img src={Mobile} alt={'mobile'}/>
                        <p>
                            شماره همراه
                        </p>
                        <p>
                            ۰۹۰۵۷۸۳۷۸۸۷
                        </p>
                    </div>
                    <div>
                        <a href={'http://wa.me/989057837887'} target={'_blank'} style={{display: "flex", flexDirection: "column", alignItems: 'center'}}>
                            <img src={Whatsapp} alt={'whatsapp'}/>
                            <p style={{color: "black", fontSize: "12px", margin: "4px 0px 0px"}}>
                                واتساپ پیگیری سفارشات
                            </p>
                        </a>
                        <a href={'http://t.me/+989057837887'} target={'_blank'} style={{display: "flex", flexDirection: "column", alignItems: 'center'}}>
                            <img src={Telegram} alt={'telegram'}/>
                            <p style={{color: "black", fontSize: "12px", margin: "4px 0px 0px"}}>
                                تلگرام پیگیری سفارشات
                            </p>
                        </a>
                    </div>
                </div>
                
                <p style={{fontFamily: "Aviny", fontSize: "24px", margin: "16px 0px 12px"}}>
                    ادرس دفتر فروش برای مراجعه حضوری
                </p>
                <p>
                    تهران ، خیابان جمهوری ، بین خیابان ملت و میدان بهارستان  ، پلاک ۶۰ طبقه اول ، انتهای راه رو
                </p>
                <p style={{width: "100%", textAlign: "center", fontFamily: "Aviny", fontSize: "24px", cursor: "pointer", display: "flex", justifyContent: "center"}} onClick={() => window.open("https://www.google.com/maps?q=35.694610,51.434272")}>
                    <img src={GoogleMaps} style={{verticalAlign: "middle", width: "32px", marginLeft: "2px"}} alt="google-map"/>برای مسیریابی کلیک کنید.
                </p>
                <div>
                    <div style={{display: "flex", flexDirection: "row", marginBottom: "4px"}}>
                        <img style={{marginLeft: "10px", width: "calc(50% - 10px)"}} src={require("../../assets/icons/locationImg1.jpg")} alt="toomar"/>
                        <img style={{marginRight: "10px", width: "calc(50% - 10px)"}} src={require("../../assets/icons/locationImg2.jpg")} alt="toomar"/>
                    </div>
                    <img style={{marginBottom: "4px"}} width={"100%"} src={require("../../assets/icons/locationImg3.jpg")} alt="toomar"/>
                    <img width={"100%"} src={require("../../assets/icons/locationImg4.jpg")} alt="toomar"/>
                </div>
                <p>
                    پست الکترونیک
                </p>
                <p>
                    info@toomar.co
                </p>
                <FollowUsInSocials/>
            </div>
                
        </MainContainer>
    )
}

export default ContactUs;